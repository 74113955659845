import { slug } from "github-slugger";

export const humanize = (content: string) => {
  if (!content) return null;

  return content
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

export const slugify = (content: string) => {
  if (!content) return null;
  return slug(content);
};

export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>/g, '');
}

export const decodeLineBreaks = (input: string) => {
  return input.replace(/\\n/g, "<br>");
};
